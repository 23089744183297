import ReactOnRails from 'react-on-rails';
import CommerceMore from '../bundles/Aplication/components/CommerceMore';
import FooterDropdown from '../bundles/Aplication/components/FooterDropdown';
import LoadGoogleAd from '../bundles/Aplication/components/LoadGoogleAd';
import JsonRenderer from '../bundles/Aplication/components/JsonRenderer';
import ListToggler from '../bundles/Aplication/components/ListToggler';
import Header from '../bundles/Aplication/components/Header';
import PictureTag from '../bundles/Aplication/components/PictureTag';
import HomeHeader from '../bundles/Aplication/header/HomeHeader';
import EventsFilter from '../bundles/Business/components/EvenstFilter';
import ServiceTabs from '../bundles/Business/components/ServiceTabs';
import CommerceSlides from '../bundles/Commerce/CommerceSlides';
import OptionsButton from '../bundles/Commerce/OptionsButton';
import ScheduleTooltip from '../bundles/Commerce/ScheduleTooltip';
import CallToAction from '../bundles/Directory/components/CallToAction';
import CategoryList from '../bundles/Directory/components/CategoryList';
import DirectoryFilters from '../bundles/Directory/components/DirectoryFilters';
import DualDropdown from '../bundles/Directory/components/DualDropdown';
import HighTierSlideShow from '../bundles/Directory/components/HighTierSlideShow';
import LowTierSlideShow from '../bundles/Directory/components/LowTierSlideShow';
import MobileFilters from '../bundles/Directory/components/MobileFilters';
import PriceRange from '../bundles/Directory/components/PriceRange';
import SubcategoryList from '../bundles/Directory/components/SubcategoryList';
import CategoryCard from '../bundles/Directory/components/CategoryCard';
import EventCardsSlideShow from '../bundles/Directory/EventCardsSlideShowComponent';
import Category from '../bundles/Directory/pages/Category';
import Directory from '../bundles/Directory/pages/Directory';
import EventCategoryMobile from '../bundles/Events/components/EventCategoryMobile';
import EventCategoryOrder from '../bundles/Events/components/EventCategoryOrder';
import EventCategorySidebar from '../bundles/Events/components/EventCategorySidebar';
import EventFilters from '../bundles/Events/components/EventFilters';
import EventMobileFilters from '../bundles/Events/components/EventMobileFilters';
import Event from '../bundles/HomePage/components/Event';
import Hero from '../bundles/HomePage/components/Hero';
import SearchBar from '../bundles/HomePage/components/SearchBar';
import Procedure from '../bundles/HomePage/components/Procedure';
import CommercePlans from '../bundles/Prices/CommercePlans';
import RecommendedEventsSlideshow from '../bundles/slideshows/RecommendedEventsSlideshow';

// New Components
import Galery from '../bundles/Components/Galery';
import GrandstandVoice from '../bundles/Components/GrandstandVoice';
import Printed from '../bundles/Components/Printed';
import ArticleTypeFilter from '../bundles/Components/ArticleTypeFilter';
import GrandstandVideo from '../bundles/Components/GrandstandVideo';
import VideoPlayer from '../bundles/Components/VideoPlayer';
import OttHeader from '../bundles/Components/ott/OttHeader';
import StreamingHeader from '../bundles/Components/ott/StreamingHeader'
import VideoHeader from '../bundles/Components/ott/VideoHeader'
import Playlist from '../bundles/Components/Playlist';
import Share from '../bundles/Components/Share';
import ShowMore from '../bundles/Components/ShowMore';
import GoogleMapsApi from '../bundles/Components/GoogleMapsApi';
import CountrySelect from '../bundles/Components/CountrySelect';
import StripeCheckout from '../bundles/Components/StripeCheckout';
import CountdownTimer from '../bundles/Components/CountdownTimer';

// This is how react_on_rails can see the HelloWorld in the browser.
//
// We need to "register" all components in here
//
// Let's use alphabetical order
ReactOnRails.register({
  ArticleTypeFilter,
  Directory,
  DirectoryFilters,
  CallToAction,
  Category,
  CategoryCard,
  CommerceMore,
  CommerceSlides,
  CountrySelect,
  CountdownTimer,
  Event,
  EventCardsSlideShow,
  VideoPlayer,
  EventCategoryMobile,
  EventCategoryOrder,
  EventCategorySidebar,
  EventFilters,
  EventsFilter,
  EventMobileFilters,
  Galery,
  GoogleMapsApi,
  GrandstandVideo,
  GrandstandVoice,
  JsonRenderer,
  Header,
  FooterDropdown,
  Hero,
  HomeHeader,
  LoadGoogleAd,
  OttHeader,
  StreamingHeader,
  VideoHeader,
  Playlist,
  Procedure,
  EventsFilter,
  ServiceTabs,
  SearchBar,
  ListToggler,
  LowTierSlideShow,
  HighTierSlideShow,
  CategoryList,
  PriceRange,
  Printed,
  PictureTag,
  MobileFilters,
  RecommendedEventsSlideshow,
  SubcategoryList,
  DualDropdown,
  CommercePlans,
  ScheduleTooltip,
  Share,
  ShowMore,
  StripeCheckout,
  OptionsButton,
});

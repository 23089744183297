import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/style.css";
import { mexicoStates } from "../data/mexicoStates";

const CountrySelect = ({
  selectedCountry,
  selectedState,
  whatsapp,
  errors,
  countrySelectElement,
}) => {
  const [country, setCountry] = useState(selectedCountry.toLowerCase());
  const [state, setState] = useState(selectedState);

  useEffect(() => {
    let countrySelect = document.getElementById("participant_country");

    countrySelect.addEventListener("change", (e) => {
      setCountry(e.target.value.toLowerCase());
    });
  }, []);

  return (
    <>
      <div className="form-field">
        <label htmlFor="country">
          <span>País</span>
        </label>
        <div dangerouslySetInnerHTML={{ __html: countrySelectElement }} />
        <p className="error-msg">{errors["country"]}</p>
      </div>

      {country == "mx" && (
        <div className="form-field">
          <label htmlFor="state">
            <span>Estado</span>
          </label>
          <select
            value={state}
            onChange={({ target }) => setState(target.value)}
            name="participant[state]"
          >
            <option>Selecciona un estado</option>
            {mexicoStates.map((s) => (
              <option key={s.value} value={s.value}>
                {s.label}
              </option>
            ))}
          </select>
          <p className="error-msg">{errors["state"]}</p>
        </div>
      )}

      <div className="form-field">
        <PhoneInput
          preferredCountries={["mx"]}
          country={country}
          value={whatsapp}
          localization={es}
          specialLabel="Whatsapp"
          inputProps={{
            name: "participant[whatsapp]",
            required: true,
          }}
        />
        <p className="error-msg">{errors["whatsapp"]}</p>
      </div>
    </>
  );
};

export default CountrySelect;
